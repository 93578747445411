<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab>
        <template v-slot:title>
          검색
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>

        <order-es-form ref="esForm" v-model="form.list" v-bind="{busy, optionsName: $options.name, statUri: '/order/fieldStat',
          formOptions, formFields, defaultFields, formIncExc, defaultIncExc}" :collapse.sync="collapse" @search="list()" @getEsQuery="getEsQuery()">
        </order-es-form>

        <div class="mt-2 clearfix">
          <div class="pull-left">
            <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
            <b-button class="mr-1" variant="warning" @click="resetForm()">초기화</b-button>
            <b-button class="mr-1" variant="outline-primary" @click="modal.downIds = true">검색결과 ID Down</b-button>
            <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseES>상세검색조건</b-button>
          </div>
          <div class="pull-right">
            <form-preset v-model="form.list" v-bind="{page: $options.name, defaultForm: defaultForm.list}" @resetForm="resetForm()"></form-preset>
          </div>
        </div>

        <hr />

        <div class="clearfix mb-1">
          <div class="pull-left col-form-label" v-if="total.list">
            Total {{total.list.value.comma()}} items
            <b-btn size="sm" class="ml-1" variant="info" @click="modal.fields = true">SetFields</b-btn>
          </div>
          <div class="pull-right">
            <b-btn class="ml-1" variant="success" @click="downXlsx">Xlsx</b-btn>
            <b-dropdown right variant="light" class="ml-1">
              <template #button-content>
                <i class="fa fa-copy"></i>
              </template>
              <b-dropdown-item @click="copy('oid')">OID</b-dropdown-item>
              <b-dropdown-item @click="copy('order_detailno')">일련번호</b-dropdown-item>
              <b-dropdown-item @click="copy('gm_id')">GM ID</b-dropdown-item>
              <b-dropdown-item @click="copy('goodsno')">발란코드</b-dropdown-item>
              <b-dropdown-item @click="copy('goodsno', {separator: 'tab'})">발란코드(탭)</b-dropdown-item>
              <b-dropdown-item @click="copy('goodsno', {separator: 'comma'})">발란코드(콤마)</b-dropdown-item>
              <b-dropdown-item @click="copy('goodsno', {separator: 'quotes'})">발란코드(따옴표)</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <htb ref="htb" v-model="items.list" :config="htbConfig" height="calc(100vh - 520px)"></htb>
      </b-tab>
      <b-tab>
        <template v-slot:title>
          통계
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>

        <b-row>
          <b-col cols="12" xl="9">
            <order-es-form ref="esStat" v-model="form.list" v-bind="{busy, optionsName: $options.name, statUri: '/order/fieldStat',
              formOptions, formFields, defaultFields, formIncExc, defaultIncExc, isStat: true}" :collapse.sync="collapse" @search="stat()" @getEsQuery="getEsQuery()">
            </order-es-form>

            <div class="mt-2 clearfix">
              <div class="pull-left">
                <b-button class="mr-1" variant="primary" @click="stat()" :disabled="busy.stat">통계<b-spinner class="ml-1" small v-if="busy.stat"></b-spinner></b-button>
                <b-button class="mr-1" variant="warning" @click="resetStatForm()">초기화</b-button>
                <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseES>상세검색조건</b-button>
              </div>
              <div class="pull-right">
                <form-preset v-model="form.list" v-bind="{page: $options.name, defaultForm: defaultForm.list}" @resetForm="resetForm()"></form-preset>
              </div>
            </div>
            <b-card class="my-2">
              <pie-chart chart-id="pie" :chartdata="chart.data" :options="chart.options" style="position: relative; height: 450px"></pie-chart>
            </b-card>

            <b-card class="my-2">
              <div class="clearfix mb-1">
                <div class="pull-left col-form-label" v-if="total.stat">
                  Total {{total.stat.value.comma()}} / Aggregations {{items.aggs.length}}
                </div>
                <b-btn class="pull-right" variant="success" @click="statXlsx()" :disabled="busy.statXlsx">
                  Xlsx Down
                  <b-spinner class="ml-1" small v-if="busy.statXlsx"></b-spinner>
                </b-btn>
              </div>

              <hot-table ref="hotTableStat" :settings="hotSettingsStat"></hot-table>
            </b-card>
          </b-col>
          <b-col class="border-left" cols="12" xl="3">
            <div class="fs-12 bold">
              Group By
            </div>
            <v-select v-model="form.aggs.groupFields" :options="groupFields" index="value" multiple push-tags placeholder="컬럼 선택"
                      @input="groupFieldsChange()"></v-select>
            <template v-for="(f, idx) in form.aggs.groupFields">
              <div class="mt-2" v-if="form.aggs.groupFieldSetting[f]" :key="idx">
                <div class="fs-12 bold">
                  {{groupFields.find(e => e.value === f).label}} Group Size
                  <i class="fa fa-question-circle ml-1" v-b-tooltip="'갯수가 많은 순으로 Group Size 만큼 가져오고 나머지는 (OTHER_COUNT) 에 포함됩니다.'"></i>
                </div>
                <b-form-input type="number" class="text-center w-100px" v-model.number="form.aggs.groupFieldSetting[f].size" placeholder="group size"></b-form-input>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].avgOn">AVG</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].avgOn" v-model="form.aggs.groupFieldSetting[f].avg"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].minOn">MIN</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].minOn" v-model="form.aggs.groupFieldSetting[f].min"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].maxOn">MAX</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].maxOn" v-model="form.aggs.groupFieldSetting[f].max"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].sumOn">SUM</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].sumOn" v-model="form.aggs.groupFieldSetting[f].sum"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
              </div>
            </template>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <b-modal title="ES Query 확인" v-model="modal.esQuery">
      <b-textarea v-model="esQuery" rows="50"></b-textarea>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="tabIndex === 0" variant="success" @click="runCustomQuery">
          이 쿼리로 실행
        </b-button>
        <b-button variant="primary" @click="ok">
          확인
        </b-button>
      </template>
    </b-modal>

    <b-modal title="전체 검색결과 ID 다운로드" size="lg" v-model="modal.downIds" :no-close-on-backdrop="busy.downIds" :no-close-on-esc="busy.downIds" hide-header-close>
      <b-alert show variant="info">ID 필드를 선택하여 전체 검색결과에 대해 다운로드 합니다.</b-alert>
      <b-alert show variant="warning">다운로드는 200 만개 까지 가능하며, 그 이상은 조건을 조절하여 순차 다운르드 해 주세요. 다운로드에는 10 만개 기준 20 여초 소요됩니다.</b-alert>
      <b-alert show variant="warning">다운로드 중에는 모달을 닫을 수 없습니다. 먼저 검색을 통해 전체 수를 확인 후 다운을 진행해주세요.</b-alert>
      <span>ID Field</span>
      <b-form-radio-group class="col-form-label" v-model="returnIdField" :disabled="busy.downIds" :options="[
        {text: 'OID', value: 'oid'},
        {text: '일련번호', value: 'order_detailno'},
        {text: '발란코드(goods_no)', value: 'goods_no'},
        {text: 'GM ID', value: 'gm_id'},
        {text: 'ObjectId', value: '_object_id'}
      ]"></b-form-radio-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="downIds()" :disabled="busy.downIds">
          다운로드
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()" :disabled="busy.downIds">
          닫기
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <b-modal title="필드 설정" v-model="modal.fields" ok-title="적용" cancel-title="취소" @ok="applyViewFields">
      <h6>필드를 선택하여 볼 수 있습니다.</h6>
      <div class="mb-2">
        <b-btn class="mr-1" size="sm" variant="primary" @click="applyViewFields(true)">적용</b-btn>
        <b-btn size="sm" @click="modal.fields = false">취소</b-btn>
<!--        <b-btn class="mr-1" size="sm" variant="success" @click="toggleFieldAll(true)">전체선택</b-btn>-->
<!--        <b-btn size="sm" variant="warning" @click="toggleFieldAll(false)">전체해제</b-btn>-->
      </div>
      <b-form-checkbox-group v-model="viewFields" stacked>
        <template v-for="f in formFields">
          <h5 class="mt-3" v-if="f.group" :key="f.group">{{f.group}}</h5>
          <b-form-checkbox v-else-if="!f.role || $R(f.role)" class="mr-0" :value="f" :key="f.key">
            {{f.name}} ({{f.key}})
          </b-form-checkbox>
        </template>
      </b-form-checkbox-group>
    </b-modal>
  </div>
</template>

<script>
import {getHost} from '@/shared/api'
import {down} from '@/shared/impexp'
import Vue from "vue";
import FormPreset from "../FormPreset";
import OrderEsForm from "./OrderEsForm";
import PieChart from '@/views/charts/Pie.vue'
import {formFieldsGen, formOptionsGen, formIncExcGen, groupFieldsGen} from "./Order/formData";
import htb from '@/views/modules/HotTableBase.vue'
import Handsontable from "handsontable";


export default {
  name: 'OrderES',
  title: '주문검색엔진',
  components: {FormPreset, PieChart, OrderEsForm, htb},
  data() {
    return {
      getHost,
      defaultForm: {
        list: {
          search: '',
          phoneSearch: '',
          orderDateFrom: this.$moment().add(-1, 'month').format('YYYY-MM-DD'),
          orderDateTo: this.$moment().format('YYYY-MM-DD'),
          shop: [],
          brand: [],
          category: [],
          shop_exclude: [],
          brand_exclude: [],
          category_exclude: [],

          shop_type: 'ALL',
          delivery_type: 'ALL',
          logistics: 'ALL',
          today_pick: 'ALL',
          // oneday_delivery: 'ALL',
          store_pickup: 'ALL',
          // gift_packing: 'ALL',
          dp_mode: 'ALL',

          limit: 100,
          skip: 0,

          sortKey: '_object_id',
          sortDir: 'desc',
          fields: {},
          incExc: {},
        },
        aggs: {
          // for stat
          groupFields: [],
          groupFieldSetting: {},
        }
      },
      form: {
        list: {},
        aggs: {}
      },
      tabIndex: 0,
      esQuery: '',
      returnIdField: 'oid',
      collapse: {esDetail: false},
      lastBody: {list: {}},
      items: {list: [], xlsx: []},
      total: {list: null, stat: null}, // list: {value: 0, relation: 'eq'}
      hasMore: {list: false},
      ac: {list: null}, // abortController
      htbConfig: {
        fields: [
          {
            key: '_json', name: 'JSON', width: 60, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              // console.log(this.listAllProperties(cellProperties), cellProperties.className);
              // console.log(td.className, cellProperties.className, td);
              td.className = cellProperties.className;
              Handsontable.dom.empty(td);
              const onclick = () => this.$modal.show({title: 'JSON', item: this.items.list[cellProperties.row], type: 'json'});

              // A - html 교체로 생성
              td.innerHTML = `<button class="btn btn-light btn-sm" style="padding: 1px 5px; height:22px">JSON</button>`;
              td.firstChild.onclick = onclick;

              // B - element 생성 후 추가
              // const btn = document.createElement('BUTTON');
              // btn.className = 'btn btn-light btn-sm';
              // btn.innerText = '보기';
              // btn.onclick = onclick;
              // td.appendChild(btn);

              return td;
            }
          },
          {
            key: 'oid', name: 'OID',
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              td.className = cellProperties.className;
              Handsontable.dom.empty(td);
              const item = this.items.list[cellProperties.row];
              const [orderNo, orderDetailNo] = item.oid.split('|');
              const orderDate = encodeURIComponent(item.order_date);
              td.innerHTML = `<a href="/#/deliveryBoard/${orderNo}/${orderDetailNo}?orderDateFrom=${orderDate}&orderDateTo=${orderDate}" target="_blank">${item.oid}</a>`;
              return td;
            }
          },
          {key: 'order_dt', name: '주문일시'},
          {key: 'order_status', name: '주문상태'},
          {key: 'goodsno', name: '발란코드'},
          {key: 'sales_price', name: '거래액'},
          // {key: 'goodsnm', name: '상품명'},
          // {key: 'option', name: '상품옵션'},
          // {key: 'shop_id', name: 'SHOP ID'},
          // {key: 'boutique', name: 'SHOP 이름'},
          // {key: 'orderer_mobile', name: '주문자 전화번호'},
        ],
        settings: {
          contextMenu: true,
          filters: true,
          dropdownMenu: true,
        }
      },
      viewFields: [],
      defaultViewFields: 'order_dt,order_status,goodsno,sales_price,shop_id,brand_no'.split(','),

      busy: {
        search: false,
        list: false,
        listmore: false,
        downIds: false,
        stat: false,
        statXlsx: false,
      },
      modal: {
        esQuery: false,
        downIds: false,
        fields: false,
      },
      xlsx: {
        keys: [],
        labels: [],
      },
      lastQuery: {},

      formOptions: formOptionsGen(this),
      formFields: formFieldsGen(),
      defaultFields: 'price:range,sku_id:eq,matched_sku_id:eq,b_rank:eq,season:eq,option.Size:like',
      customFormFields: [],
      formIncExc: formIncExcGen(),
      defaultIncExc: 'oid,order_detailno,goods_no,gm_id',
      customFormIncExc: [],

      // stat
      groupFields: groupFieldsGen(),
      chart: {
        data: {
          labels: ['Chart'],
          datasets: [{data: [1]}]
        },
        options: {
          // title: {display: true, text: 'Chart Title'},
          legend: {
            position: 'left'
          }
        }
      },
      hotSettingsStat: {
        colHeaders: [],
        columns: [],
        className: "htMiddle",
        autoWrapCol: true,
        autoWrapRow: false,
        columnSorting: true,
        manualColumnResize: true,
        width: '100%',
        height: 450,
        stretchH: "all",
        licenseKey: 'non-commercial-and-evaluation',
      },
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'collapse');
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));
    Vue.set(this.form, 'aggs', this.$utils.clone(this.defaultForm.aggs));
    for (const f of this.groupFields) {
      f.label = `${f.text}(${f.value})`;
    }
    this.viewFields = this.defaultViewFields.map(e => this.formFields.find(f => f.key === e)).filter(e => e);
    this.applyViewFields();

    this.busy.list = true;
  },
  mounted() {
    this.list();
  },
  watch: {
    collapse: {
      deep: true,
      handler() {
        this.$utils.setStatus(this.$options.name, this, 'collapse');
      }
    },
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'collapse');
  },
  methods: {
    makeListFormBody() {
      const form = this.form.list;

      const shop = form.shop.map(e => e.shop_id);
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      const brand_exclude = form.brand_exclude.map(e => e.value);
      const category_exclude = form.category_exclude.map(e => e.value);

      const fields = this.$refs.esForm.makeFieldsQuery();
      const incExc = this.$refs.esForm.makeIncExcQuery();

      const body = {
        ...form,
        shop,
        brand,
        category,
        shop_exclude,
        brand_exclude,
        category_exclude,
        fields,
        incExc,
      };
      return body;
    },
    async list() {
      const body = this.makeListFormBody();
      await this.$api.postTable(this, '/order/es', body, {fnAssign: this.assignTableData});
      this.render();
    },
    assignTableData(e) {
      Object.entries(e).forEach(([k, v]) => {
        if (typeof v === 'object') {
          // e[k] = JSON.stringify(v, null, 2);
        }
      });
    },

    async getEsQuery() {
      const body = this.makeListFormBody();
      body.returnQuery = true;
      const j = await this.$api.postJson('/order/es', body);
      if (j) {
        this.esQuery = JSON.stringify(j.query, null, 2);
        this.modal.esQuery = true;
      }
    },
    async runCustomQuery() {
      try {
        const customQuery = JSON.parse(this.esQuery);
        const body = this.makeListFormBody();
        this.modal.esQuery = false;
        await this.$api.postTable(this, '/order/es', {...body, customQuery}, {more: false, fnAssign: this.assignTableData});
      } catch (e) {
        alert('JSON 형식에 맞게 입력해주세요');
      }
    },
    async downIds() {
      const body = this.makeListFormBody();
      body.returnIdField = this.returnIdField;
      this.busy.downIds = true;
      const j = await this.$api.postJson('/order/es', body);
      if (j) {
        down(j.data.map(e => ({id: e})), [body.returnIdField], ['id'], `OrderIds_${this.$utils.dt()}`, 'txt');
        //
        // this.$refs.json_data.value = JSON.stringify({
        //   data: j.data.map(e => ({id: e})),
        //   keys: ['id'],
        //   labels: [body.returnIdField],
        //   type: 'txt',
        //   name: `ConfirmedIds_${this.$utils.dt()}.txt`
        // });
        // this.$refs.xlsx_form.submit();
      }
      this.busy.downIds = false;
    },
    copy(col, {separator} = {}) {
      const selected = this.items.list;

      let res;
      if (separator === 'tab') {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join('\t'));
      } else if (separator === 'comma') {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join(','));
      } else if (separator === 'quotes') {
        res = this.$utils.copyToClipboard(selected.map(e => `'${e[col].toString().replace(/'/g, "\\'")}'`).join(',\n'));
      } else {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join('\n'));
      }

      if (res) this.$alertTop(`복사되었습니다`);
    },
    applyViewFields(hide) {
      if (hide) this.modal.fields = false;
      this.htbConfig.fields.splice(2, this.htbConfig.fields.length - 2, ...this.viewFields);

      if (this.$refs.htb) {
        this.htbConfig.settings.columns = this.htbConfig.fields.map(e => this.$refs.htb.fieldToColumn(e))
      }
    },
    toggleFieldAll(check) {
      if (check) this.fields = this.formFields.filter(e => e.key).slice();
      else this.fields.splice(0, this.fields.length);
    },
    async downXlsx() {
      const fields = this.htbConfig.fields.slice(1).map(e => e.key);
      down(this.items.list, fields, fields, `OrderES_${this.$utils.kstDT()}`, 'xlsx');
    },

    async stat() {
      const form = this.form.list;
      const aggs = this.form.aggs;

      if (aggs.groupFields.length === 0) return alert('1 개 이상의 Group By 필드를 선택해 주세요');

      const shop = form.shop.map(e => e.shop_id);
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      const brand_exclude = form.brand_exclude.map(e => e.value);
      const category_exclude = form.category_exclude.map(e => e.value);

      const fieldMap = this.$utils.arr2map(this.groupFields, 'value');
      const selectedFields = aggs.groupFields.map(e => fieldMap[e]);

      if (selectedFields.some(e => e.nested)) {
        const firstNestedIndex = selectedFields.indexOf(selectedFields.find(e => e.nested));
        if (selectedFields.slice(firstNestedIndex + 1).some(e => !e.nested)) {
          return alert('nested field(option.Size 등 depth 가 있는 내부 필드) 는 일반 field 뒤에 존재해야 합니다');
        }
      }

      // const shop = form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      // const brand = form.brand.map(e => e.value);
      // const category = form.category.map(e => e.value);
      // const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      // const brand_exclude = form.brand_exclude.map(e => e.value);
      // const category_exclude = form.category_exclude.map(e => e.value);

      const fields = this.$refs.esForm.makeFieldsQuery();
      const incExc = this.$refs.esForm.makeIncExcQuery();

      const body = {
        form: {
          ...form,
          fields,
          incExc,
          shop,
          brand,
          category,
          shop_exclude,
          brand_exclude,
          category_exclude,
        },
        aggs
      };
      this.busy.stat = true;
      const j = await this.$api.postJson('/order/aggs', body);
      this.busy.stat = false;
      if (j) {
        this.items.aggs = j.rows;
        const top19 = j.rows.slice(0, 19);
        this.chart.data = {
          labels: top19.map(e => aggs.groupFields.map(f => e[f] == null ? `(null)` : `${e[f]}`).join(' / ')),
          datasets: [{data: top19.map(e => e.count)}]
        };
        if (j.rows.length > 19) {
          const etcCount = j.rows.slice(19).sum('count');
          this.chart.data.labels.push('(기타)');
          this.chart.data.datasets[0].data.push(etcCount);
        }
        this.total.stat = j.total;

        const hs = this.$utils.clone(this.hotSettingsStat);
        hs.colHeaders = aggs.groupFields.map(f => {
          const {avgOn, avg, minOn, min, maxOn, max, sumOn, sum} = aggs.groupFieldSetting[f];
          return [f, avgOn && `${f} avg(${avg})`, minOn && `${f} min(${min})`, maxOn && `${f} max(${max})`, sumOn && `${f} sum(${sum})`, f + ' count'].filter(e => e);
        }).flat().slice(0, -1).concat(['count']);
        hs.columns = hs.colHeaders.map(e => ({data: e, readOnly: true, renderer: 'nullish'}));
        // hs.columns = hs.colHeaders.map(e => ({data: e, readOnly: true}));
        this.$refs.hotTableStat.hotInstance.updateSettings(hs);
        this.$refs.hotTableStat.hotInstance.loadData(j.rows);

        this.$forceUpdate();
      }
    },
    groupFieldsChange() {
      const settingKeyMap = this.$utils.arr2map(Object.keys(this.form.aggs.groupFieldSetting));
      this.form.aggs.groupFields.forEach(f => {
        if (!settingKeyMap[f]) {
          const orgField = this.groupFields.find(e => e.value === f);
          Vue.set(this.form.aggs.groupFieldSetting, f, {size: 100, type: orgField.type, nested: orgField.nested, avgOn: false, minOn: false, maxOn: false, sumOn: false});
        }
        delete settingKeyMap[f];
      });
      Object.keys(settingKeyMap).forEach(k => {
        delete this.form.aggs.groupFieldSetting[k];
      });
    },
    statXlsx() {
      const keys = this.form.aggs.groupFields.map(f => [f, f + ' count']).flat().slice(0, -1).concat(['count']);
      down(this.items.aggs, keys, keys, `OrderStat_${this.$utils.dt()}`, 'xlsx');
    },
    resetForm() {
      const fields = this.form.list.fields;
      const incExc = this.form.list.incExc;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      Vue.set(this.form.list, 'incExc', incExc);

      this.$refs.esForm.reset();
    },
    resetStatForm() {
      this.resetForm();
      this.form.aggs = this.$utils.clone(this.defaultForm.aggs);
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
    },
  }
}
</script>

<style>
.cf_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.cf_desc {word-break: break-word;}
.cf_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.cf_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

.mp_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.mp_desc {word-break: break-word;}
.mp_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.mp_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

</style>
