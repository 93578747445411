<template>
  <div>
    <b-row class="mb-2">
      <b-col md="8">
        <b-input-group class="mb-1">
          <b-input-group-prepend>
            <b-button variant="primary" @click="search()" :disabled="busy.search">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="ml-1" small v-if="busy.search"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="아직 지원하지 않습니다" v-model="form.search" @keypress.enter="search()" disabled></b-form-input>
          <b-input-group-append>
            <b-button v-if="$R('DEV')" variant="light" @click="getEsQuery()">쿼리확인</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="4">
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="primary" @click="search()" :disabled="busy.search">
              <i class="fa fa-search"></i> 전화번호 검색
              <b-spinner class="ml-1" small v-if="busy.search"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input id="search" type="text" placeholder="ex: 01012345678 or 5678" v-model="form.phoneSearch" @keypress.enter="search()"
                        autocomplete="off"></b-form-input>
          <b-input-group-append>
            <b-button variant="light" @click="$utils.open('https://soapy-painter-778.notion.site/b79b0bae5d514ecfbd7fc6372565a17e')"><i
              class="fa fa-question"></i></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <form-options v-model="form" v-bind="{formOptions, isStat}">
      <template v-slot:slot0>
        <div class="flex-grow-0 mb-1 mr-2">
          <small>주문일</small><br/>
          <date-from-to :from.sync="form.orderDateFrom" :to.sync="form.orderDateTo" v-bind="{init: '1 month', all: true, absMonth: 12, year: 5}" @enter="search()">
          </date-from-to>
        </div>
      </template>
      <template v-slot:slotEnd>
        <div class="flex-grow-0 mb-1 mr-2">
          <small>기본 상품수</small><br/>
          <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.limit" @keypress.enter="search()"></b-form-input>
        </div>
      </template>
    </form-options>

    <b-collapse id="collapseES" v-bind="_collapse.esDetail">
      <hr/>
      <b-row>
        <b-col class="mb-1" cols="12" md="12" xl="4">
          <shop-preset v-model="form.shop"></shop-preset>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" xl="4">
          <brand-preset class="" v-model="form.brand" :hideDisabled="true"></brand-preset>
        </b-col>
        <b-col class="mb-1" cols="12" md="6" xl="4">
          <category-preset class="" v-model="form.category"></category-preset>
        </b-col>
      </b-row>
      <div class="mt-n2">
        <small class="pointer text-warning" v-b-toggle.collapseExc>제외할 Shop, Brand, Category 설정</small>
        <div class="py-1">
          <b-collapse id="collapseExc" class="b-a-1 m-n1 p-2 border-warning">
            <b-row>
              <b-col class="mb-1" cols="12" md="12" xl="4">
                <shop-preset v-model="form.shop_exclude"></shop-preset>
              </b-col>
              <b-col class="mb-1" cols="12" md="6" xl="4">
                <brand-preset class="" v-model="form.brand_exclude" :hideDisabled="true"></brand-preset>
              </b-col>
              <b-col class="mb-1" cols="12" md="6" xl="4">
                <category-preset class="" v-model="form.category_exclude"></category-preset>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </div>

      <div class="fs-12 bold">
        검색 필드
      </div>
      <form-fields ref="fields" v-model="form.fields" :name="optionsName" :customFormFields.sync="customFormFields"
                   v-bind="{formFields, defaultFields, statUri}" @enter="search()"></form-fields>
      <form-inc-exc ref="incExc" v-model="form.incExc" :name="optionsName" :customFormIncExc.sync="customFormIncExc"
                    v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>
    </b-collapse>
  </div>
</template>

<script>
import FormIncExc from "../modules/FormIncExc.vue";
import FormOptions from "../modules/FormOptions.vue";
import FormFields from "../modules/FormFields.vue";

export default {
  name: 'OrderEsForm',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    busy: Object,
    collapse: Object,

    optionsName: String,
    statUri: String,
    formOptions: {type: Array, default: null},
    formFields: {type: Array, default: null},
    defaultFields: {type: String, default: ''},
    formIncExc: {type: Array, default: null},
    defaultIncExc: {type: String, default: ''},
    isStat: {type: Boolean, default: false},
  },
  components: {FormFields, FormOptions, FormIncExc},
  data() {
    return {
      customFormFields: [],
      customFormIncExc: [],
    }
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    _collapse: {
      get() {
        return this.collapse;
      },
      set(v) {
        this.$emit('update:collapse', v);
      }
    },
  },
  methods: {
    search(v) {
      this.$emit('search', v);
    },
    getEsQuery() {
      this.$emit('getEsQuery');
    },
    makeFieldsQuery() {
      return this.$refs.fields.makeFieldsQuery() || [];
    },
    makeIncExcQuery() {
      return this.$refs.incExc.makeIncExcQuery() || [];
    },
    reset() {
      this.$refs.fields.resetFieldValues();
      this.$refs.incExc.resetValues();
    }
  }
}
</script>
