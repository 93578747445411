import {formOptionsPreset} from "@/shared/fields";
export const formOptionsGen = () => {
  return [
    [
      // 탑재 안시킴
      // {name: 'SHOP 유형', key: 'shop_type', options: [
      //     {text: '전체', value: 'ALL'},
      //     {text: '부티크', value: 'boutique'},
      //     {text: '편집샵', value: 'edit_shop'},
      //     {text: '병행', value: 'parallel'},
      //     {text: '자사', value: 'balaan'},
      //     {text: '스토어', value: 'store'},
      //     {text: '기타', value: 'etc'},
      //   ]},
      {
        name: '배송유형', key: 'delivery_type', options: [
          {text: '전체', value: 'ALL'},
          {text: '해외', value: '해외', variant: 'success'},
          {text: '국내', value: '국내', variant: 'primary'},
        ]
      },
      // {name: '직배송여부', key: 'logistics', options: [
      //     {text: '전체', value: 'ALL'},
      //     {text: '직배송', value: 'direct'},
      //     {text: '발란물류', value: 'balaan'},
      //   ]},
      {name: '오늘출발', key: 'today_pick', options: formOptionsPreset.AVAIL_Y},
      // {name: '오늘도착', key: 'oneday_delivery', options: formOptionsPreset.AVAIL_Y},
      {name: '매장픽업', key: 'store_pickup', options: formOptionsPreset.AVAIL_Y},
      // {name: '선물포장', key: 'gift_packing', options: formOptionsPreset.AVAIL_Y},
      {name: 'DynamicPricing', key: 'dp_mode', options: [
          {text: '전체', value: 'ALL'},
          {text: '가격변화없음', value: 'unchanged'},
          {text: '달성함', value: 'positive'},
          {text: '미달성', value: 'negative'},
          {text: '고정할인', value: 'fixed'},
        ]},

      // {key: 'divider'},
      {
        name: '정렬기준', key: 'sortKey', options: [
          {text: '생성시각', value: '_object_id', variant: 'primary'},
          {text: '주문일시', value: 'order_dt', variant: 'info'},
          {text: '배송완료일시', value: 'del_complete_dt', variant: 'info'},
          {text: '거래액', value: 'sales_price', variant: 'info'},
        ], hideInStat: true
      },
      {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR, hideInStat: true},
    ],
  ];
};

export const formFieldsGen = () => {
  return [
    {group: '상품 flag'},
    {name: '최초 생성위치', key: '_cat', type: 'string'},
    {name: '최초 생성시각', key: '_cdt', type: 'string'},
    {name: '최초 생성 ID', key: '_cuid', type: 'string', disableRange: true},
    {name: '마지막 변경시각', key: '_mdt', type: 'string'},

    {group: '상품 고유번호'},
    {name: 'OID', key: 'oid', type: 'string', disableRange: true},
    {name: '주문코드', key: 'orderno', type: 'string', disableRange: true},
    {name: '일련번호', key: 'order_detailno', type: 'string'},

    {group: '상품속성'},
    {name: '발란코드', key: 'goodsno', type: 'number', renderer: 'text'},
    {name: 'Shop ID', key: 'shop_id', type: 'number'},
    {name: '브랜드코드', key: 'brand_no', type: 'number'},
    {name: '카테고리코드', key: 'category', type: 'string', disableRange: true},
    {name: '옵션사이즈', key: 'option.Size', type: 'string', op: 'like'},
    {name: '옵션명', key: 'option.optnm', type: 'string'},
    {name: '옵션판매가', key: 'option.goods_price', type: 'number', op: 'range'},
    {name: '배송유형', key: 'delivery_type', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true},
    {name: '상품타입', key: 'goodsType', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '중고등급', key: 'usedGrade', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: 'B Rank', key: 'b_rank', type: 'number', enableExists: true},
    {name: 'B Rank Class', key: 'b_rank_class', type: 'string', enableExists: true},
    {name: 'GM ID', key: 'gm_id', type: 'object', enableExists: true},
    {name: '발란추천가 달성여부', key: 'gm_isMinPrice', type: 'boolean'},
    {name: '발란 추천가', key: 'gm_minPriceAll', type: 'number', enableExists: true},
    {name: '14일 최저판매가', key: 'gm_minSalePrice14d', type: 'number', enableExists: true},
    {name: 'B 최저가', key: 'gm_minPriceBalaan', type: 'number', enableExists: true},
    {name: 'B 최저가 달성여부', key: 'gm_isBalaanMinPrice', type: 'boolean', op: 'eq', enableExists: true},
    {name: '마스터 매칭상태', key: 'gm_status', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '마스터 처리상태', key: 'gm_processing_status', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '마스터 노출상태', key: 'gm_display_status', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: 'DP mode', key: 'dp_mode', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '반품배송비', key: 'return_cost', type: 'number'},
    {name: '반품배송비 유형', key: 'return_cost_type', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '교환배송비', key: 'exchange_cost', type: 'number'},
    {name: '교환배송비 유형', key: 'exchange_cost_type', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '파트너센터 상품 여부', key: 'manual', type: 'boolean'},
    // {name: '가격 정보', key: 'price_table', type: 'object'},
    {name: 'SKU', key: 'sku', type: 'string', enableExists: true, disableRange: true},
    {name: '통합 SKU', key: 'matched_sku_id', type: 'string', enableExists: true, disableRange: true},

    {group: '주문속성'},
    {name: '주문자', key: 'orderer', type: 'string', disableRange: true},
    {name: '주문자 휴대폰', key: 'orderer_mobile', type: 'string', disableRange: true},
    {name: '주문자 전화번호', key: 'orderer_phone', type: 'string', disableRange: true},
    {name: '수령자', key: 'receiver', type: 'string', disableRange: true},
    {name: '수령자 전화번호', key: 'receiver_mobile', type: 'string', disableRange: true},
    {name: '수령자 휴대폰', key: 'receiver_phone', type: 'string', disableRange: true},
    {name: '옵션명', key: 'size', type: 'string', disableRange: true},
    {name: '수량', key: 'qty', type: 'number'},
    {name: '동일주문 상품수', key: 'multiple', type: 'number'},
    {name: '총판매액', key: 'sales_price', type: 'number'},
    {name: '쇼핑몰', key: 'mall', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '발란몰 회원번호', key: 'mall_info.m_no', type: 'number'},
    {name: '발란몰 PG', key: 'mall_info.pg', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '발란몰 결제수단', key: 'mall_info.settlekind', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '발란몰 쿠폰금액', key: 'mall_info.coupon', type: 'number'},
    {name: '발란몰 이머니사용액', key: 'mall_info.emoney', type: 'number'},
    {name: '발란몰 이머니적립액', key: 'mall_info.reserve', type: 'number'},
    {name: '글로벌쿠폰율', key: 'global_coupon_rate', type: 'number'},
    {name: '우편번호', key: 'post_code', type: 'string', disableRange: true},
    {name: '주소', key: 'address', type: 'string', disableRange: true},
    {name: '기본주소', key: 'base_address', type: 'string', disableRange: true},
    {name: '배송기재사항', key: 'del_memo', type: 'string', disableRange: true},
    {name: '직배송여부', key: 'logistics', type: 'string', enableEnum: true, enableStat: true},
    {name: '당일배송 여부', key: 'oneday_delivery', type: 'string', enableEnum: true, enableStat: true},
    {name: '매장픽업 여부', key: 'store_pickup', type: 'boolean'},
    {name: '매장픽업 예약일', key: 'store_pickup_date', type: 'string'},
    {name: '오늘출발 선택여부', key: 'today_pick', type: 'boolean'},
    {name: '발송기한', key: 'today_pick_deadline', type: 'string'},
    {name: '오늘출발 마감시각', key: 'today_pick_time', type: 'string', enableStat: true},
    {name: '선물포장', key: 'gift_packing', type: 'boolean'},
    {name: '개인통관고유부호', key: 'pccc', type: 'string', disableRange: true},
    {name: '통관수령자 연락처', key: 'pccc_mobile', type: 'string', disableRange: true},
    {name: '통관수령자 실명', key: 'pccc_name', type: 'string', disableRange: true},

    {group: '주문상태 관련'},
    {name: '주문상세상태', key: 'order_status', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '주문상세상태코드', key: 'order_status_code', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '취소, 반품 이전 주문상세상태코드', key: 'bf_claim_status_code', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '주문일', key: 'order_date', type: 'string'},
    {name: '주문일시', key: 'order_dt', type: 'string'},
    {name: '결제일', key: 'payment_date', type: 'string'},
    {name: '결제일시', key: 'payment_dt', type: 'string'},
    {name: '주문접수일', key: 'order_receive_date', type: 'string'},
    {name: '주문접수일시', key: 'order_receive_dt', type: 'string'},
    {name: '품절 일자', key: 'withdraw_date', type: 'string'},
    {name: '품절 일시', key: 'withdraw_dt', type: 'string'},
    {name: '배송준비일', key: 'del_prepare_date', type: 'string'},
    {name: '배송준비일시', key: 'del_prepare_dt', type: 'string'},
    {name: '해외 배송 완료일', key: 'del_abroad_complete_date', type: 'string'},
    {name: '해외 배송 완료 일시', key: 'del_abroad_complete_dt', type: 'string'},
    {name: '해외배송일', key: 'del_abroad_date', type: 'string'},
    {name: '해외배송일시', key: 'del_abroad_dt', type: 'string'},
    {name: '해외배송 시작일', key: 'del_abroad_start_date', type: 'string'},
    {name: '해외배송 시작일시', key: 'del_abroad_start_dt', type: 'string'},
    {name: '국내배송일', key: 'del_domestic_date', type: 'string'},
    {name: '국내배송일시', key: 'del_domestic_dt', type: 'string'},
    {name: '국내배송 시작일', key: 'del_domestic_start_date', type: 'string'},
    {name: '국내배송 시작일시', key: 'del_domestic_start_dt', type: 'string'},
    {name: '배송완료일', key: 'del_complete_date', type: 'string'},
    {name: '배송완료일시', key: 'del_complete_dt', type: 'string'},
    {name: '취소승인일 (YYYY-MM-DD)', key: 'cancel_approve_date', type: 'string'},
    {name: '취소승인일 (YYYY-MM-DD HH:mm:ss)', key: 'cancel_approve_dt', type: 'string'},
    {name: '취소확인일 (YYYY-MM-DD)', key: 'cancel_complete_date', type: 'string'},
    {name: '취소확인일 (YYYY-MM-DD HH:mm:ss)', key: 'cancel_complete_dt', type: 'string'},
    {name: '취소요청일', key: 'cancel_request_date', type: 'string'},
    {name: '취소요청일시', key: 'cancel_request_dt', type: 'string'},
    {name: '취소완료일', key: 'change_complete_date', type: 'string'},
    {name: '취소완료일시', key: 'change_complete_dt', type: 'string'},
    {name: '반품 요청일', key: 'refund_request_date', type: 'string'},
    {name: '반품 요청 일시', key: 'refund_request_dt', type: 'string'},
    {name: '회수 접수일', key: 'retrieve_request_date', type: 'string'},
    {name: '회수 접수 일시', key: 'retrieve_request_dt', type: 'string'},
    {name: '회수 완료일', key: 'retrieve_complete_date', type: 'string'},
    {name: '회수 완료 일시', key: 'retrieve_complete_dt', type: 'string'},
    {name: '반품 승인일', key: 'refund_approve_date', type: 'string'},
    {name: '반품 승인 일시', key: 'refund_approve_dt', type: 'string'},
    {name: '반품 완료 일', key: 'refund_complete_date', type: 'string'},
    {name: '반품 완료 일시', key: 'refund_complete_dt', type: 'string'},
    {name: '파트너 반품일', key: 'refund_partner_date', type: 'string'},
    {name: '파트너 반품 일시', key: 'refund_partner_dt', type: 'string'},

    {group: '배송속성'},
    {name: '해외택배사', key: 'abroad_del_company', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '해외운송장', key: 'abroad_invoice_no', type: 'string', disableRange: true},
    {name: '국내택배사', key: 'domestic_del_company', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '국내운송장', key: 'domestic_invoice_no', type: 'string', disableRange: true},
    {name: 'goodsflow 픽업일', key: 'gf_complete_d', type: 'string'},
    {name: 'goodsflow 픽업일시', key: 'gf_complete_dt', type: 'string'},
    {name: 'goodsflow 처리일', key: 'gf_pickup_d', type: 'string'},
    {name: 'goodsflow 처리일시', key: 'gf_pickup_dt', type: 'string'},
    {name: '리얼패킹 작업자', key: 'real_packing_src', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '배송 예상일', key: 'del_plan_date', type: 'string'},
    {name: '예상 배송일', key: 'del_expected_date', type: 'string'},
    {name: '배송지연(일)', key: 'del_delay_day', type: 'number'},
    {name: '배송소요(일)', key: 'del_taken_day', type: 'number'},
    {name: '배송 기간', key: 'delivery_day', type: 'number'},
    {name: '배송지연 태그', key: 'delay', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '환불지연 태그', key: 'delay_refund', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '잘못된 운송장 여부 (국내)', key: 'is_wrong_invoice', type: 'boolean'},
    {name: '해외직배송 운송장 중복 여부', key: 'abroad_direct', type: 'string', enableEnum: true, enableStat: true, disableRange: true},

    {group: '클레임 속성'},
    {name: '클레임 비용', key: 'claim_cost', type: 'number'},
    {name: '클레임 비용 목록', key: 'claim_item.name', type: 'string', enableEnum: true, enableStat: true, disableRange: true, nested: true},
    {name: '클레임사유', key: 'claim_memo', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '파트너 반품비', key: 'partner_claim_cost', type: 'number'},
    {name: '파트너 반품비 목록', key: 'partner_claim_item.name', type: 'string', enableEnum: true, enableStat: true, disableRange: true, nested: true},
    {name: '반품비 수정 사유', key: 'partner_claim_modified_reason', type: 'string', disableRange: true},
    {name: '운영 Log', key: 'op_log.content', type: 'string', enableExists: true, disableRange: true, nested: true},
    {name: 'PS 주문 관리 로그', key: 'ps_log.content', type: 'string', enableExists: true, disableRange: true, nested: true},
    {name: '파트너 주문 관리 log', key: 'mng_log.content', type: 'string', enableExists: true, disableRange: true, nested: true},
    {name: 'PS 메모', key: 'ps_memo', type: 'string', disableRange: true},
    {name: '고객클레임메모', key: 'user_claim_memo', type: 'string', disableRange: true},
    // {name: '고객클레임이미지', key: 'user_claim_img', type: 'string', enableExists: true, disableRange: true},
    {name: '철회 이력', key: 'withdraw_flag', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '은행 코드', key: 'refund_bank_code', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '환불 은행명', key: 'refund_bank_name', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '환불 계좌번호', key: 'refund_account_number', type: 'string', enableExists: true, disableRange: true},
    {name: '환불 계좌 예금주명', key: 'refund_account_owner_name', type: 'string', enableExists: true, disableRange: true},

    {group: '반품속성'},
    {name: '반품 운송사', key: 'return_del_company', type: 'string', enableEnum: true, enableStat: true, disableRange: true},
    {name: '반품 운송장', key: 'return_invoice_no', type: 'string', disableRange: true},
    {name: '반품 메모', key: 'return_memo', type: 'string', disableRange: true},

    {group: '정산속성'},
    // {name: '구매확정 데이터', key: 'confirmation', type: 'object'},
    // {name: '정산 정보', key: 'account', type: 'object'},
    {name: '정산 완료여부', key: 'account_completed', type: 'boolean'},
    {name: '정산 완료일', key: 'account_completed_date', type: 'string'},
    {name: '배송완료 정산예정일', key: 'account_expected_date', type: 'string'},
    // {name: '정산 금액', key: 'settle_data', type: 'object'},
    {name: '정산일', key: 'settle_date', type: 'string'},
    {name: '정산 예정일', key: 'expected_settlement_date', type: 'string'},
    {name: '기존 정산 예정일', key: 'org_expected_settlement_date', type: 'string'},

    // {group: '추가 상품속성'},
    // {name: '이미지', key: 'img_urls', type: 'object'},

    {group: '추가 주문속성'},
    {name: '주문 교체 백업 이력', key: 'ordershift', type: 'object', disableRange: true, disableLike: true, enableExists: true},
    {name: 'api 주문 결과', key: 'auto_order', type: 'object', disableRange: true, disableLike: true, enableExists: true},

    // {group: '추가 배송속성'},
    // {name: '리얼패킹 S3 URL', key: 'real_packing_url', type: 'string', enableExists: true, disableRange: true},
    // {name: '리얼패킹 원본 URL', key: 'real_packing_url_org', type: 'string', enableExists: true, disableRange: true},

    {group: '추가 내부 속성'},
    {name: '가격테이블 재계산 이유', key: '_price_table_recalc_reason', type: 'string', enableExists: true, disableRange: true},
  ];
};

export const formIncExcGen = () => {
  return [
    {name: 'OID', key: 'oid', type: 'string'},
    {name: '주문코드', key: 'orderno', type: 'string'},
    {name: '일련번호', key: 'order_detailno', type: 'string'},
    {name: '발란코드', key: 'goodsno', type: 'number'},
    {name: 'GM ID', key: 'gm_id', type: 'string'},
    {name: '파트너상품코드', key: 'goods_id', type: 'string'},
    {name: 'Sku ID', key: 'sku_id', type: 'string'},
    {name: '통합 Sku', key: 'matched_sku_id', type: 'string'},
    {name: '옵션사이즈', key: 'option.Size', type: 'string'},
    {name: 'B Rank', key: 'b_rank', type: 'number'},
    {name: 'B Rank Class', key: 'b_rank_class', type: 'string'},
    {name: '해외운송장', key: 'abroad_invoice_no', type: 'string'},
    {name: '국내운송장', key: 'domestic_invoice_no', type: 'string'},
  ];
}

export const groupFieldsGen = () => {
  return [
    {text: '주문상세상태', value: 'order_status', type: 'string'},
    {text: '주문상세상태코드', value: 'order_status_code', type: 'string'},
    {text: 'Shop ID', value: 'shop_id', type: 'number'},
    {text: '브랜드코드', value: 'brand_no', type: 'number'},
    {text: '카테고리코드', value: 'category', type: 'string'},
    {text: '옵션사이즈', value: 'option.Size', type: 'string'},
    {text: '배송유형', value: 'delivery_type', type: 'string'},
    {text: '상품타입', value: 'goodsType', type: 'string'},
    {text: '중고등급', value: 'usedGrade', type: 'string'},
    {text: 'B Rank', value: 'b_rank', type: 'number'},
    {text: 'B Rank Class', value: 'b_rank_class', type: 'string'},
    {text: '발란추천가 달성여부', value: 'gm_isMinPrice', type: 'boolean'},
    {text: 'B 최저가 달성여부', value: 'gm_isBalaanMinPrice', type: 'boolean'},
    {text: '마스터 매칭상태', value: 'gm_status', type: 'string'},
    {text: '마스터 처리상태', value: 'gm_processing_status', type: 'string'},
    {text: '마스터 노출상태', value: 'gm_display_status', type: 'string'},
    {text: 'DP mode', value: 'dp_mode', type: 'string'},
    {text: '수량', value: 'qty', type: 'number'},
    {text: '동일주문 상품수', value: 'multiple', type: 'number'},
    {text: '쇼핑몰', value: 'mall', type: 'string'},
    {text: '발란몰 PG', value: 'mall_info.pg', type: 'string'},
    {text: '발란몰 결제수단', value: 'mall_info.settlekind', type: 'string'},
    {text: '글로벌쿠폰율', value: 'global_coupon_rate', type: 'number'},
    {text: '직배송여부', value: 'logistics', type: 'string'},
    {text: '당일배송 여부', value: 'oneday_delivery', type: 'string'},
    {text: '매장픽업 여부', value: 'store_pickup', type: 'boolean'},
    {text: '오늘출발 선택여부', value: 'today_pick', type: 'boolean'},
    {text: '오늘출발 마감시각', value: 'today_pick_time', type: 'string'},
    {text: '선물포장', value: 'gift_packing', type: 'boolean'},
    {text: '해외택배사', value: 'abroad_del_company', type: 'string'},
    {text: '국내택배사', value: 'domestic_del_company', type: 'string'},
    {text: '리얼패킹 작업자', value: 'real_packing_src', type: 'string'},
    {text: '배송 예상일', value: 'del_plan_date', type: 'string'},
    {text: '예상 배송일', value: 'del_expected_date', type: 'string'},
    {text: '배송지연(일)', value: 'del_delay_day', type: 'number'},
    {text: '배송소요(일)', value: 'del_taken_day', type: 'number'},
    {text: '배송 기간', value: 'delivery_day', type: 'number'},
    {text: '배송지연 태그', value: 'delay', type: 'string'},
    {text: '환불지연 태그', value: 'delay_refund', type: 'string'},
    {text: '잘못된 운송장 여부 (국내)', value: 'is_wrong_invoice', type: 'boolean'},
    {text: '해외직배송 운송장 중복 여부', value: 'abroad_direct', type: 'string'},
    {text: '클레임 비용 목록', value: 'claim_item.name', type: 'string'},
    {text: '클레임사유', value: 'claim_memo', type: 'string'},
    {text: '파트너 반품비', value: 'partner_claim_cost', type: 'number'},
    {text: '파트너 반품비 목록', value: 'partner_claim_item.name', type: 'string'},
    {text: '철회 이력', value: 'withdraw_flag', type: 'string'},
    {text: '은행 코드', value: 'refund_bank_code', type: 'string'},
    {text: '환불 은행명', value: 'refund_bank_name', type: 'string'},
    {text: '반품 운송사', value: 'return_del_company', type: 'string'},
    {text: '정산 완료여부', value: 'account_completed', type: 'boolean'},

  ];
};
